<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <textarea class="form-control" v-show="!component_to_label"
    v-bind:class="{ 'is-invalid': component_message != '' && component_message != undefined }" v-bind:id="component_id"
    v-bind:name="component_meta.variable" @change="input_event" v-bind:value="component_value"
    v-bind:rows="component_rows">
  </textarea>
  <div class="invalid-mssage">{{ component_message }}</div>

  <div v-show="component_to_label">
    {{ component_value }}
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let localValue = this.value;
    if (localValue == undefined) {
      localValue = this.field.component.properties.default;
    }

    let localMeta = this.field;
    if (localMeta.component.properties.show_label == undefined) {
      localMeta.component.properties.show_label = '';
    }

    return {
      // 元件識別碼
      component_id: 'textarea-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: localMeta,

      // 元件值
      component_value: localValue,

      // 元件訊息
      component_message: this.message,

      // 行數
      component_rows: localMeta.component.properties.rows,

      // 元件是否被標籤化
      component_to_label: (localMeta.component.properties.to_label == 'Y') ? true : false,
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {

    validate: function (value) {

      this.component_message = '';

      // 是否必填
      if (this.component_meta.component.properties.required == 'Y' && value == '') {
        this.component_message = '必填';
      }

      // 最大字數：這個欄位最多可以幾個字(中文算一個字)
      if (this.component_meta.component.properties.max != undefined && this.component_meta.component.properties.max != '' && value.length > this.component_meta.component.properties.max) {
        this.component_message = '最多' + this.component_meta.component.properties.max + '個字';
      }

      // 最小字數：這個欄位最少可以幾個字(中文算一個字)
      if (this.component_meta.component.properties.min != undefined && this.component_meta.component.properties.min != '' && value.length < this.component_meta.component.properties.min) {
        this.component_message = '最少' + this.component_meta.component.properties.min + '個字';
      }

      // 驗證 regex 格式
      if (this.component_meta.component.properties.regex != undefined && this.component_meta.component.properties.regex != '') {
        let regex = new RegExp(this.component_meta.component.properties.regex);
        if (!regex.test(value)) {
          this.component_message = '格式錯誤';
        }
      }

      return this.component_message;
    },

    input_event: function ($event) {

      // 驗證
      this.validate($event.target.value);

      this.component_value = $event.target.value;
      this.$emit('input', $event)
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
</style>